import { gql, useMutation } from 'glimmer-apollo';
import {
	Crop,
	CropCategory,
	CropPricingMethodology,
	DeletedResource,
	Mutation,
	Mutation_createCropArgs,
	Mutation_createCropFieldLedgerCategoryArgs,
	Mutation_createFieldArgs,
	Mutation_createPhysicalCropTransactionArgs,
	Mutation_deleteCropArgs,
	Mutation_deleteFeedIngredientArgs,
	Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs,
	Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs,
	Mutation_updateCropArgs,
	Mutation_updateFieldArgs,
	Mutation_setCropHarvestsArgs,
	TypeOfCropFieldLedger,
	Mutation_deleteManyCropLedgerEntriesPerHarvestYearArgs,
	Mutation_setManyCropLedgerEntriesPerHarvestYearArgs,
	Mutation_updatePhysicalCropTransactionArgs,
	CropTransaction,
	TypeOfPhysicalCropTransactionPricing,
	Mutation_setCropPricesByYearArgs,
	CropPrice,
	Future,
	CropHarvestYear,
	AggregateCurrentAllocationPositionDTO,
	type CropLedgerEntryPerHarvestYear,
	type FieldLedgerEntryPerHarvestYear,
} from 'vault-client/types/graphql-types';
import { analyticsCustomTracking } from './analytics-tracking';
import { tracked } from 'tracked-built-ins';
import { assert, debug } from '@ember/debug';
import Big from 'big.js';
import productSlugToPricingUnit from './product-slug-to-pricing-unit';
import { ProductSlug } from 'vault-client/types/vault-client';
import { safeSum } from 'vault-client/utils/precision-math';
import { isProductSlug } from './type-utils';

export type VolumeUnit = 'Bushel' | 'Ton';
export type CreateFieldData = { error: string; name: string; acres: string; businessId: string };
export type UpdateFieldData = { error: string; name: string; acres: string };
export type CreateRevExpCategoryData = {
	error: string;
	name: string;
	description: string;
	businessId: string;
	type: TypeOfCropFieldLedger;
};

type GetCropFieldLedgerCategory = {
	createCropFieldLedgerCategory?: {
		id: string;
		name: string;
	};
};

const CREATE_GRAIN_FIELD = gql`
	mutation CreateField($data: FieldCreateDTO!) {
		createField(data: $data) {
			id
			acres
			name
		}
	}
`;

const UPDATE_GRAIN_FIELD = gql`
	mutation UpdateField($data: FieldUpdateDTO!, $id: String!) {
		updateField(data: $data, id: $id) {
			id
			acres
			name
		}
	}
`;

const CREATE_FIELD_REVENUE_EXPENSE_CATEGORY = gql`
	mutation CreateCropFieldLedgerCategory($data: CropFieldLedgerCategoryCreateDTO!) {
		createCropFieldLedgerCategory(data: $data) {
			id
			name
		}
	}
`;

const SET_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR = gql`
	mutation SetManyFieldLedgerEntriesPerHarvestYear($data: [FieldLedgerEntryPerHarvestYearCreateDTO!]!) {
		setManyFieldLedgerEntriesPerHarvestYear(data: $data) {
			id
		}
	}
`;

const DELETE_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR = gql`
	mutation DeleteManyFieldLedgerEntriesPerHarvestYear($ids: [String!]!) {
		deleteManyFieldLedgerEntriesPerHarvestYear(ids: $ids) {
			id
		}
	}
`;

const SET_MANY_CROP_LEDGER_ENTRIES_PER_HARVEST_YEAR = gql`
	mutation SetManyCropLedgerEntriesPerHarvestYear($data: [CropLedgerEntryPerHarvestYearCreateDTO!]!) {
		setManyCropLedgerEntriesPerHarvestYear(data: $data) {
			id
		}
	}
`;

const DELETE_MANY_CROP_LEDGER_ENTRIES_PER_HARVEST_YEAR = gql`
	mutation DeleteManyCropLedgerEntriesPerHarvestYear($ids: [String!]!) {
		deleteManyCropLedgerEntriesPerHarvestYear(ids: $ids) {
			id
		}
	}
`;

async function updateField(context: object, variables: Mutation_updateFieldArgs) {
	const mutation = useMutation<{ updateField: Mutation['updateField'] }, Mutation_updateFieldArgs>(context, () => [
		UPDATE_GRAIN_FIELD,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to update field: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

async function createRevExpCategory(context: object, variables: Mutation_createCropFieldLedgerCategoryArgs) {
	const mutation = useMutation<{ createCategory: GetCropFieldLedgerCategory }, Mutation_createCropFieldLedgerCategoryArgs>(context, () => [
		CREATE_FIELD_REVENUE_EXPENSE_CATEGORY,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to create new category: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.evict({ fieldName: 'CropLedgerEntriesPerHarvestYear' });
				cache.evict({ fieldName: 'CropFieldLedgerCategories' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

async function setManyFieldLedgerEntriesPerHarvestYear(context: object, variables: Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs) {
	const mutation = useMutation<
		{ setManyFieldLedgerEntriesPerHarvestYear: Mutation['setManyFieldLedgerEntriesPerHarvestYear'] },
		Mutation_setManyFieldLedgerEntriesPerHarvestYearArgs
	>(context, () => [
		SET_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to update field ledger entries: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.evict({ fieldName: 'CropFieldLedgerCategories' });
				cache.evict({ fieldName: 'FieldLedgerEntriesPerHarvestYear' });
				cache.gc();
			},
		},
	]);
	await mutation.mutate(variables);
	return mutation.data;
}

async function deleteManyFieldLedgerEntriesPerHarvestYear(
	context: object,
	variables: Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs,
) {
	const mutation = useMutation<
		{ deleteManyFieldLedgerEntriesPerHarvestYear: Mutation['deleteManyFieldLedgerEntriesPerHarvestYear'] },
		Mutation_deleteManyFieldLedgerEntriesPerHarvestYearArgs
	>(context, () => [
		DELETE_MANY_FIELD_LEDGER_ENTRIES_PER_HARVEST_YEAR,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to delete field ledger entries: ${variables.ids}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.evict({ fieldName: 'CropFieldLedgerCategories' });
				cache.evict({ fieldName: 'FieldLedgerEntriesPerHarvestYear' });
				cache.gc();
			},
		},
	]);
	await mutation.mutate(variables);
	return mutation.data;
}

async function setManyCropLedgerEntriesPerHarvestYear(context: object, variables: Mutation_setManyCropLedgerEntriesPerHarvestYearArgs) {
	const mutation = useMutation<
		{ setManyCropLedgerEntriesPerHarvestYear: Mutation['setManyCropLedgerEntriesPerHarvestYear'] },
		Mutation_setManyCropLedgerEntriesPerHarvestYearArgs
	>(context, () => [
		SET_MANY_CROP_LEDGER_ENTRIES_PER_HARVEST_YEAR,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to update crop ledger entries: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.evict({ fieldName: 'CropFieldLedgerCategories' });
				cache.evict({ fieldName: 'CropLedgerEntriesPerHarvestYear' });
				cache.gc();
			},
		},
	]);
	await mutation.mutate(variables);
	return mutation.data;
}

async function deleteManyCropLedgerEntriesPerHarvestYear(
	context: object,
	variables: Mutation_deleteManyCropLedgerEntriesPerHarvestYearArgs,
) {
	const mutation = useMutation<
		{ deleteManyCropLedgerEntriesPerHarvestYear: Mutation['deleteManyCropLedgerEntriesPerHarvestYear'] },
		Mutation_deleteManyCropLedgerEntriesPerHarvestYearArgs
	>(context, () => [
		DELETE_MANY_CROP_LEDGER_ENTRIES_PER_HARVEST_YEAR,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to delete crop ledger entries: ${variables.ids}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.evict({ fieldName: 'CropFieldLedgerCategories' });
				cache.evict({ fieldName: 'CropLedgerEntriesPerHarvestYear' });
				cache.gc();
			},
		},
	]);
	await mutation.mutate(variables);
	return mutation.data;
}

function parseFieldData(data: CreateFieldData | UpdateFieldData) {
	if ('businessId' in data) {
		return {
			name: data.name.trim(),
			acres: typeof data.acres == 'string' ? parseFloat(data.acres.trim()) : data.acres,
			businessId: data.businessId,
		};
	} else {
		return {
			name: data.name.trim(),
			acres: typeof data.acres == 'string' ? parseFloat(data.acres.trim()) : data.acres,
		};
	}
}

function parseFieldCategoryLedgerData(data: CreateRevExpCategoryData) {
	return {
		name: data.name.trim(),
		description: data.description.trim(),
		businessId: data.businessId,
		type: data.type,
	};
}

export type CreateCropData = {
	businessId: string;
	categoryId: string;
	cropCategory: CropCategory | null;
	name: string;
	defaultPrice: string;
	error?: string;
	cropPriceType: CropPricingMethodology;
};

const pricingMethodologyMap: Record<string, CropPricingMethodology> = {
	'Spot Price': CropPricingMethodology.Flat,
	'Basis (USD)': CropPricingMethodology.CmeUsdBasis,
	'Basis %': CropPricingMethodology.CmePercentBasis,
};

export const TYPE_OF_CROP_PRICING_LABELS = {
	[CropPricingMethodology.Flat]: 'Spot Price',
	[CropPricingMethodology.CmeUsdBasis]: 'Basis (USD)',
	[CropPricingMethodology.CmePercentBasis]: 'Basis %',
};

export const cornCategorySlugs: ProductSlug[] = ['grain-corn'];
export const isCornCategorySlug = (slug: unknown) => isProductSlug(slug) && cornCategorySlugs.includes(slug);

export const soyBeanCategorySlugs: ProductSlug[] = ['grain-soybeans', 'grain-soybean-meal', 'grain-soybean-oil'];
export const isSoybeanCategorySlug = (slug: unknown) => isProductSlug(slug) && soyBeanCategorySlugs.includes(slug);

export const wheatCategorySlugs: ProductSlug[] = [
	'grain-hard-red-spring-wheat',
	'grain-chicago-soft-red-winter-wheat',
	'grain-hrwi-hard-red-winter-wheat',
	'grain-srwi-soft-red-winter-wheat',
	'grain-kansas-city-hard-red-wheat',
];
export const isWheatCategorySlug = (slug: unknown) => isProductSlug(slug) && wheatCategorySlugs.includes(slug);

export const otherCommoditySlugs = ['grain-canola'];

export function getVolumeUnitBySlug(slug: string | null | undefined): VolumeUnit {
	const isTradedCommodity = slug && !!productSlugToPricingUnit[slug];
	return isTradedCommodity ? 'Bushel' : 'Ton';
}

export function isVolumeUnit(maybeVolumeUnit: unknown): maybeVolumeUnit is VolumeUnit {
	return typeof maybeVolumeUnit === 'string' && (maybeVolumeUnit === 'Bushel' || maybeVolumeUnit === 'Ton');
}

export type FormatGrainVolumeUnitOverrides = Partial<Record<VolumeUnit, string>>;
const FORMAT_GRAIN_VOLUME_UNIT_MAP: Record<VolumeUnit, string> = {
	Bushel: 'Bu',
	Ton: 'Tons',
};

export function formatGrainVolumeUnit<T>(volumeUnit: T, overrides?: FormatGrainVolumeUnitOverrides) {
	if (!isVolumeUnit(volumeUnit)) return volumeUnit;

	return overrides?.[volumeUnit] ?? FORMAT_GRAIN_VOLUME_UNIT_MAP[volumeUnit];
}

const CREATE_GRAIN_CROP = gql`
	mutation CreateCrop($data: CropCreateDTO!) {
		createCrop(data: $data) {
			businessId
			cropCategoryId
			name
			price
			pricingMethodology
		}
	}
`;

async function createCrop(context: object, variables: Mutation_createCropArgs) {
	const mutation = useMutation<{ createCrop: Mutation['createCrop'] }, Mutation_createCropArgs>(context, () => [
		CREATE_GRAIN_CROP,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to create crop: ${variables.data.name}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

async function createField(context: object, variables: Mutation_createFieldArgs) {
	const mutation = useMutation<{ createField: Mutation['createField'] }, Mutation_createFieldArgs>(context, () => [
		CREATE_GRAIN_FIELD,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to create field: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

function parseCropData(data: CreateCropData) {
	const pricingMethodology = pricingMethodologyMap[data.cropPriceType];
	if (!pricingMethodology) {
		throw new Error(`Unknown pricing methodology type: ${data.cropPriceType}`);
	}

	const price = parseFloat(data.defaultPrice);
	if (isNaN(price)) {
		throw new Error(`Invalid price value: ${data.defaultPrice}`);
	}

	const transformedPrice = pricingMethodology === CropPricingMethodology.CmePercentBasis ? Big(price).div(100).toNumber() : price;

	return {
		name: data.name.trim(),
		businessId: data.businessId,
		cropCategoryId: data.cropCategory?.id ?? data.categoryId,
		pricingMethodology,
		price: transformedPrice,
	};
}

export type EditCropFormData = {
	cropId: string;
	cropCategory: CropCategory;
	name: string;
	defaultPrice: string;
	error?: string;
	cropPriceType: CropPricingMethodology;
};

const UPDATE_GRAIN_CROP = gql`
	mutation UpdateCrop($id: String!, $data: CropUpdateDTO!) {
		updateCrop(id: $id, data: $data) {
			businessId
			cropCategoryId
			name
			price
			pricingMethodology
		}
	}
`;

export function parseEditCropFormDataFromCrop(crop: Crop): EditCropFormData {
	return tracked({
		cropId: crop.id,
		cropCategory: crop.Category,
		name: crop.name,
		defaultPrice: crop.price?.toString(),
		cropPriceType: crop.pricingMethodology,
	});
}

export function validateEditCropFormData(data: EditCropFormData) {
	if (data.cropId !== undefined && data.cropPriceType !== undefined && !isNaN(parseFloat(data.defaultPrice)) && data.name !== undefined) {
		return data;
	}

	return false;
}

export function transformValidatedEditCropFormDataToUpdateCropArgs(validatedData: EditCropFormData): Mutation_updateCropArgs {
	return {
		id: validatedData.cropId,
		data: {
			name: validatedData.name,
			price: parseFloat(validatedData.defaultPrice),
			pricingMethodology: validatedData.cropPriceType,
		},
	};
}

export async function updateCrop(context: object, variables: Mutation_updateCropArgs) {
	const mutation = useMutation<{ createCrop: Mutation['updateCrop'] }, Mutation_updateCropArgs>(context, () => [
		UPDATE_GRAIN_CROP,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to update crop: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

const DELETE_CROP_MUTATION = gql`
	mutation DeleteCrop($id: String!) {
		deleteCrop(id: $id) {
			id
		}
	}
`;

type DeleteCrop = {
	__typename?: 'Mutation';
	deletedResource: DeletedResource;
};

async function deleteCrop(context: object, variables: Mutation_deleteCropArgs, customerRole?: string) {
	const mutation = useMutation<DeleteCrop, Mutation_deleteFeedIngredientArgs>(context, () => [
		DELETE_CROP_MUTATION,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error('Unable to delete crop');
			},
			onComplete: () => {
				analyticsCustomTracking('Crop Deleted', { 'Crop Delete': variables.id, 'Business Role': customerRole });
			},
			update: (cache) => {
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

const SET_CROP_HARVESTS = gql`
	mutation SetCropHarvests($cropId: String!, $data: [CropHarvestSetInput!]!) {
		setCropHarvests(cropId: $cropId, data: $data) {
			upserted
			deleted
		}
	}
`;

async function setCropHarvests(context: object, variables: Mutation_setCropHarvestsArgs) {
	const mutation = useMutation<Mutation['setCropHarvests'], Mutation_setCropHarvestsArgs>(context, () => [
		SET_CROP_HARVESTS,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error('Unable to set Crop Harvests');
			},
			update: (cache) => {
				cache.evict({ fieldName: 'CropHarvests' });
				cache.evict({ fieldName: 'CropHarvests' });
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

type CropPriceType = 'CBOT Basis (USD)' | 'CBOT Basis (%)' | 'Spot Price';

const ADD_PHYSICAL_CROP_TRANSACTION = gql`
	mutation CreatePhysicalCropTransaction($data: PhysicalCropTransactionCreateDTO!) {
		createPhysicalCropTransaction(data: $data) {
			id
		}
	}
`;

async function addPhysicalCropTransaction(context: object, variables: Mutation_createPhysicalCropTransactionArgs) {
	const mutation = useMutation<
		{ createPhysicalCropTransaction: Mutation['createPhysicalCropTransaction'] },
		Mutation_createPhysicalCropTransactionArgs
	>(context, () => [
		ADD_PHYSICAL_CROP_TRANSACTION,
		{
			onError: (error): void => {
				throw new Error(`Unable to add physical crop transaction: ${error.message}`);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'PhysicalCropTransactions' });
				cache.evict({ fieldName: 'PhysicalCropTransaction' });
				cache.evict({ fieldName: 'AggregatePhysicalCropTransactions' });
				cache.evict({ fieldName: 'CropTransactions' });
				cache.evict({ fieldName: 'CropTransaction' });
				cache.evict({ fieldName: 'AggregateCropTransactions' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

const UPDATE_PHYSICAL_CROP_TRANSACTION = gql`
	mutation UpdatePhysicalCropTransaction($id: String!, $data: PhysicalCropTransactionUpdateDTO!) {
		updatePhysicalCropTransaction(id: $id, data: $data) {
			id
		}
	}
`;

async function updatePhysicalCropTransaction(context: object, variables: Mutation_updatePhysicalCropTransactionArgs) {
	const mutation = useMutation<
		{ updatePhysicalCropTransaction: Mutation['updatePhysicalCropTransaction'] },
		Mutation_updatePhysicalCropTransactionArgs
	>(context, () => [
		UPDATE_PHYSICAL_CROP_TRANSACTION,
		{
			onError: (error): void => {
				throw new Error(`Unable to update physical crop transaction: ${error.message}`);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'PhysicalCropTransactions' });
				cache.evict({ fieldName: 'PhysicalCropTransaction' });
				cache.evict({ fieldName: 'AggregatePhysicalCropTransactions' });
				cache.evict({ fieldName: 'CropTransactions' });
				cache.evict({ fieldName: 'CropTransaction' });
				cache.evict({ fieldName: 'AggregateCropTransactions' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

function getTotalPriceForFlatCropTransaction(cropTransaction: CropTransaction): number {
	const flatPrice = cropTransaction.flatPrice;
	const bushels = cropTransaction.bushels;

	if (flatPrice == undefined) {
		debug(`flatPrice was not provided in cropTransaction ${JSON.stringify(cropTransaction)}`);
		return 0;
	}

	return Big(flatPrice).times(bushels).toNumber();
}

function getTotalPriceForHTACropTransaction(
	cropTransaction: CropTransaction,
	cropPrice: number,
	cropPricingMethodology: CropPricingMethodology,
): number {
	const { futuresPrice, bushels } = cropTransaction;
	const percentBasis = cropPricingMethodology === CropPricingMethodology.CmePercentBasis ? cropPrice : undefined;
	const usdBasis = cropPricingMethodology === CropPricingMethodology.CmeUsdBasis ? cropPrice : undefined;

	if (futuresPrice == undefined) {
		debug(`futuresPrice was not provided in cropTransaction ${JSON.stringify(cropTransaction)}`);
		return 0;
	}

	const basis = getBasis({ futuresPrice, usdBasis, percentBasis }) ?? 0;

	return Big(futuresPrice).plus(basis).times(bushels).toNumber();
}

function getTotalPriceForBasisOnlyCropTransaction(
	cropTransaction: CropTransaction,
	pointValue: number,
	lotSize: number,
	futuresPriceInPoints: number | undefined | null,
): number {
	if (futuresPriceInPoints == undefined) {
		debug('No futures Price provided. Total Price cannot be calculated');
		return 0;
	}

	const basis = cropTransaction.basisPrice;
	if (basis == undefined) {
		debug(`basisPrice was not provided in cropTransaction ${JSON.stringify(cropTransaction)}`);
		return 0;
	}

	const bushels = cropTransaction.bushels;
	const pointsPerBushel = lotSize && lotSize !== 0 ? Big(pointValue).div(lotSize) : 0;
	const futuresPriceInDollarsPerBushel = Big(futuresPriceInPoints).times(pointsPerBushel);

	return futuresPriceInDollarsPerBushel.plus(basis).times(bushels).toNumber();
}

function getAvgPriceForFlatCropTransactions(flatCropTransactions: CropTransaction[]): number {
	const [totalBushels, totalValue] = flatCropTransactions.reduce(
		([totalBushels, totalValue], flatCropTransaction) => [
			totalBushels + flatCropTransaction.bushels,
			totalValue + getTotalPriceForFlatCropTransaction(flatCropTransaction),
		],
		[0, 0],
	);

	return totalBushels ? Big(totalValue).div(totalBushels).toNumber() : 0;
}

function getAvgPriceForHTACropTransactions(
	htaCropTransactions: CropTransaction[],
	cropPrice: number,
	cropPricingMethodology: CropPricingMethodology,
): number {
	const [totalBushels, totalValue] = htaCropTransactions.reduce(
		([totalBushels, totalValue], htaCropTransactions) => [
			totalBushels + htaCropTransactions.bushels,
			totalValue + getTotalPriceForHTACropTransaction(htaCropTransactions, cropPrice, cropPricingMethodology),
		],
		[0, 0],
	);

	return totalBushels ? Big(totalValue).div(totalBushels).toNumber() : 0;
}

function getAvgPriceForBasisOnlyCropTransactions(
	basisOnlyCropTransactions: CropTransaction[],
	pointValue: number,
	lotSize: number,
	futuresPrice: number | null | undefined,
): number {
	const [totalBushels, totalValue] = basisOnlyCropTransactions.reduce(
		([totalBushels, totalValue], basisOnlyCropTransaction) => [
			totalBushels + basisOnlyCropTransaction.bushels,
			totalValue + getTotalPriceForBasisOnlyCropTransaction(basisOnlyCropTransaction, pointValue, lotSize, futuresPrice),
		],
		[0, 0],
	);

	return totalBushels && totalBushels !== 0 ? Big(totalValue).div(totalBushels).toNumber() : 0;
}

const isPricedCropTransaction = (cropTransaction: CropTransaction): boolean =>
	cropTransaction.pricingType !== TypeOfPhysicalCropTransactionPricing.NoPrice;

function getPricedCropTransactions(cropTransactions: CropTransaction[]): CropTransaction[] {
	return cropTransactions.filter(isPricedCropTransaction);
}

function getTotalBushelsForCropTransactions(cropTransactions: CropTransaction[]): number {
	return cropTransactions.reduce((totalBushels, transaction) => totalBushels + transaction.bushels, 0);
}

function getTotalBushelsForPricedCropTransactions(cropTransactions: CropTransaction[]): number {
	return getTotalBushelsForCropTransactions(getPricedCropTransactions(cropTransactions));
}

function getTotalPriceForCropTransaction(
	cropTransaction: CropTransaction,
	cropPrice: number,
	cropPricingMethodology: CropPricingMethodology,
	pointValue: number,
	lotSize: number,
	futuresPrice: number | null | undefined,
): number {
	switch (cropTransaction.pricingType) {
		case TypeOfPhysicalCropTransactionPricing.CalculatedFlat:
			return getTotalPriceForFlatCropTransaction(cropTransaction);
		case TypeOfPhysicalCropTransactionPricing.ExplicitlyFlat:
			return getTotalPriceForFlatCropTransaction(cropTransaction);
		case TypeOfPhysicalCropTransactionPricing.BasisOnly:
			return getTotalPriceForBasisOnlyCropTransaction(cropTransaction, pointValue, lotSize, futuresPrice);
		case TypeOfPhysicalCropTransactionPricing.FuturesOnly:
			return getTotalPriceForHTACropTransaction(cropTransaction, cropPrice, cropPricingMethodology);
		default:
			return 0;
	}
}

function getTotalPriceForPricedCropTransactions(
	cropTransactions: CropTransaction[],
	cropPrice: number,
	cropPricingMethodology: CropPricingMethodology,
	pointValue: number,
	lotSize: number,
	futuresPrice: number | null | undefined,
): number {
	return getPricedCropTransactions(cropTransactions).reduce(
		(totalValue, transaction) =>
			totalValue + getTotalPriceForCropTransaction(transaction, cropPrice, cropPricingMethodology, pointValue, lotSize, futuresPrice),
		0,
	);
}

function getAvgPriceForPricedCropTransactions(
	cropTransactions: CropTransaction[],
	cropPrice: number,
	cropPricingMethodology: CropPricingMethodology,
	pointValue: number,
	lotSize: number,
	futuresPrice: number | null | undefined,
): number {
	const totalBushels = getTotalBushelsForPricedCropTransactions(cropTransactions);
	const totalPrice = getTotalPriceForPricedCropTransactions(
		cropTransactions,
		cropPrice,
		cropPricingMethodology,
		pointValue,
		lotSize,
		futuresPrice,
	);

	return totalBushels ? Big(totalPrice).div(totalBushels).toNumber() : 0;
}

/**
 * Gets the price for a crop, optionally for a specific harvest year
 * @param crop The crop to get the price for
 * @param harvestYear Optional harvest year to get a specific price for
 * @returns The crop price for the specified harvest year, or the default price if no harvest year is specified or no price exists for that year
 */
function getCropPrice(crop: Crop, harvestYear?: number) {
	const { price: defaultPrice, CropPrices = [] } = crop;

	if (harvestYear) {
		return getCropPriceForHarvestYear(CropPrices, harvestYear)?.price ?? defaultPrice;
	}

	return defaultPrice;
}

export function getCropPriceForHarvestYear(cropPrices: CropPrice[], harvestYear: number) {
	return cropPrices.find((cropPrice) => cropPrice.harvestYear === harvestYear);
}

export const getFuturesPriceInDollarsPerUnit = ({
	futuresPrice,
	lotSize,
	pointValue,
}: {
	futuresPrice: number | null | undefined;
	pointValue: number;
	lotSize: number;
}) => (futuresPrice != undefined ? Big(pointValue).div(lotSize).times(futuresPrice).toNumber() : undefined);

function getCropMarketPricePerUnit(
	crop: Crop,
	pointValue: number,
	lotSize: number,
	futuresPriceInPoints: number | null | undefined,
	harvestYear?: number,
) {
	const { pricingMethodology, price, CropPrices: cropPrices = [] } = crop;
	assert('Pricing Methodology was not provided and is required.', pricingMethodology);
	assert('Price was not provided and is required.', price != undefined);

	const cropPricingMethodology = crop.pricingMethodology;

	// Prefer cropPrices from the harvest year if provided
	const cropPrice = (harvestYear && getCropPriceForHarvestYear(cropPrices, harvestYear)?.price) ?? crop.price;

	const flatPrice = cropPricingMethodology === CropPricingMethodology.Flat ? cropPrice : undefined;
	if (flatPrice != undefined) return flatPrice;

	const futuresPriceInDollarsPerUnit = getFuturesPriceInDollarsPerUnit({ futuresPrice: futuresPriceInPoints, lotSize, pointValue });
	if (futuresPriceInDollarsPerUnit == undefined) return null;

	const basisPercentage = cropPricingMethodology === CropPricingMethodology.CmePercentBasis ? cropPrice : undefined;
	const basisUsd = cropPricingMethodology === CropPricingMethodology.CmeUsdBasis ? cropPrice : undefined;
	const basis = getBasis({ futuresPrice: futuresPriceInDollarsPerUnit, usdBasis: basisUsd, percentBasis: basisPercentage });
	if (basis != undefined) return futuresPriceInDollarsPerUnit + basis;

	return null;
}

type ContractDisplayType = 'Flat' | 'HTA' | 'Basis' | 'No Price';

const pricingTypeToDisplayString: Record<TypeOfPhysicalCropTransactionPricing, ContractDisplayType> = {
	[TypeOfPhysicalCropTransactionPricing.CalculatedFlat]: 'Flat',
	[TypeOfPhysicalCropTransactionPricing.ExplicitlyFlat]: 'Flat',
	[TypeOfPhysicalCropTransactionPricing.FuturesOnly]: 'HTA',
	[TypeOfPhysicalCropTransactionPricing.BasisOnly]: 'Basis',
	[TypeOfPhysicalCropTransactionPricing.NoPrice]: 'No Price',
};

function getAllCropTransactionsByType(cropTransactions: CropTransaction[], type: ContractDisplayType) {
	return cropTransactions.filter((transaction: CropTransaction) => {
		const contractPricing = pricingTypeToDisplayString[transaction.pricingType];
		return type === contractPricing;
	});
}

const SET_CROP_PRICES_BY_YEAR = gql`
	mutation setCropPricesByYear($data: CropPricesByYearSetInput!) {
		setCropPricesByYear(data: $data)
	}
`;

async function setCropPricesByYear(context: object, variables: Mutation_setCropPricesByYearArgs) {
	const mutation = useMutation<Mutation['setCropPricesByYear'], Mutation_setCropPricesByYearArgs>(context, () => [
		SET_CROP_PRICES_BY_YEAR,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error('Unable to set crop prices');
			},
			update: (cache) => {
				cache.evict({ fieldName: 'Crop' });
				cache.evict({ fieldName: 'Crops' });
				cache.evict({ fieldName: 'CropPricesByMonth' });
				cache.evict({ fieldName: 'CropHarvestedAndSoldVolumes' });
				cache.evict({ fieldName: 'CropHarvests' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

function formatCurrency(value?: number): string {
	return Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
		style: 'currency',
		currency: 'USD',
		currencySign: 'accounting',
	}).format(value ?? 0);
}

function formatAmount(value?: number): string {
	return Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(value ?? 0);
}

function getAllCropTransactionsByCrop(crop: Crop, cropTransactions: CropTransaction[]) {
	return cropTransactions.filter((transaction: CropTransaction) => {
		return transaction.cropId === crop.id;
	});
}

function getHarvestYearFuture(crop: Crop, cropHarvestYears: CropHarvestYear[]): Future | undefined {
	return cropHarvestYears.find((harvestYear) => harvestYear.cropId === crop.id)?.ContractMonthInstrument ?? undefined;
}

function getMostCurrentFuture(crop: Crop): Future | undefined {
	return crop.Category?.HedgeProduct?.MostCurrentFuture ?? undefined;
}

function getBasis({
	futuresPrice,
	usdBasis,
	percentBasis,
}: {
	futuresPrice: number | null | undefined;
	usdBasis: number | undefined;
	percentBasis: number | undefined;
}) {
	if (usdBasis !== undefined) return usdBasis;
	if (percentBasis !== undefined) return (futuresPrice ?? 0) * percentBasis;
	return null;
}

function getBrokeragePnL(aggregateBrokeragePositions: AggregateCurrentAllocationPositionDTO[]) {
	return aggregateBrokeragePositions.reduce((sum, position) => safeSum(sum, position.sum?.grossPnl), 0);
}

function contractsRowsFn(crop: Crop, transactions: CropTransaction[]) {
	const displayByOrderType = (transaction: CropTransaction) => {
		const orderType: string = pricingTypeToDisplayString[transaction.pricingType];
		if (orderType === 'HTA') {
			return false;
		}
		return true;
	};

	return transactions.map((transaction: CropTransaction) => {
		return {
			id: transaction.id,
			orderType: pricingTypeToDisplayString[transaction.pricingType],
			contractNumber: transaction.contractIdentifier,
			crop: crop?.name,
			units: transaction.bushels,
			futuresMonth: transaction.futuresMonthStartDate,
			futuresPrice: transaction.futuresPrice,
			basisPrice: transaction.basisPrice,
			flatPrice: transaction.flatPrice,
			deliveryStartDate: displayByOrderType(transaction) ? transaction.deliveryStartDate : '',
			deliveryEndDate: displayByOrderType(transaction) ? transaction.deliveryEndDate : '',
			location: transaction.location,
			buyer: transaction.buyer,
			isManuallyAdded: transaction.isManuallyAdded,
			salesDate: transaction.salesDate,
		};
	});
}

function getHarvestedAcres(crop: Crop) {
	return crop.CropHarvests?.reduce((sum, harvest) => sum + (harvest.acres ?? 0), 0) ?? 0;
}

function calculateCropFieldLedgerTypeForHarvestYear(harvestedAcres: number, ledgerEntry: CropLedgerEntryPerHarvestYear): number {
	let totalAmount = 0;

	const addedCategories: string[] = [];

	// Add Field Level Entries
	ledgerEntry?.CropFieldLedgerCategory.FieldLedgerEntriesPerHarvestYear.forEach((entry: FieldLedgerEntryPerHarvestYear) => {
		if (
			!addedCategories.includes(entry.cropFieldLedgerCategoryId) &&
			ledgerEntry.CropFieldLedgerCategory.name === entry.CropFieldLedgerCategory.name
		) {
			totalAmount += entry.flatValueInUsd + entry.perAcreValueInUsd * harvestedAcres;
		}
		addedCategories.push(entry.cropFieldLedgerCategoryId);
	});

	// Add Crop Level Entries
	ledgerEntry?.CropFieldLedgerCategory.CropLedgerEntriesPerHarvestYear.forEach((entry: CropLedgerEntryPerHarvestYear) => {
		if (
			!addedCategories.includes(entry.cropFieldLedgerCategoryId) &&
			ledgerEntry.CropFieldLedgerCategory.name === entry.CropFieldLedgerCategory.name
		) {
			totalAmount += entry.flatValueInUsd + entry.perAcreValueInUsd * harvestedAcres;
		}
		addedCategories.push(entry.cropFieldLedgerCategoryId);
	});

	return totalAmount;
}

function calculateCropLedgerEntriesTotalValue(cropLedgerEntries: CropLedgerEntryPerHarvestYear[], harvestedAcres: number) {
	return cropLedgerEntries.reduce((sum, entry) => sum + calculateCropFieldLedgerTypeForHarvestYear(harvestedAcres, entry), 0);
}

function getUniqueProductSlugs(crops: Crop[]): ProductSlug[] {
	return Array.from(new Set(crops.map((crop) => crop.Category?.HedgeProduct?.slug).filter(isProductSlug)));
}

/**
 * Gets the field level revenues for a crop or array of crops
 * @param crop
 * @returns The field level revenues for the crop or array of crops
 */
function getFieldLevelRevenueComponents(crop: Crop | Crop[]): {
	flatRevenue: number;
	variableRevenue: number;
	totalRevenue: number;
} {
	const crops = Array.isArray(crop) ? crop : [crop];
	const flatRevenue = crops.reduce((sum, crop) => sum + (crop.RevenuesForHarvestYear?.totalUsdFromFieldFlatValues ?? 0), 0);
	const variableRevenue = crops.reduce((sum, crop) => sum + (crop.RevenuesForHarvestYear?.totalUsdFromFieldPerAcreValues ?? 0), 0);
	const totalRevenue = flatRevenue + variableRevenue;
	return { flatRevenue, variableRevenue, totalRevenue };
}

function getFieldLevelExpenseComponents(crop: Crop | Crop[]): {
	flatExpenses: number;
	variableExpenses: number;
	totalExpenses: number;
} {
	const crops = Array.isArray(crop) ? crop : [crop];
	const flatExpenses = crops.reduce((sum, crop) => sum + (crop.ExpensesForHarvestYear?.totalUsdFromFieldFlatValues ?? 0), 0);
	const variableExpenses = crops.reduce((sum, crop) => sum + (crop.ExpensesForHarvestYear?.totalUsdFromFieldPerAcreValues ?? 0), 0);
	const totalExpenses = flatExpenses + variableExpenses;
	return { flatExpenses, variableExpenses, totalExpenses };
}

function getFieldLevelPnl(crop: Crop | Crop[]): number {
	const { totalRevenue } = getFieldLevelRevenueComponents(crop);
	const { totalExpenses } = getFieldLevelExpenseComponents(crop);
	return totalRevenue - totalExpenses;
}

export {
	createField,
	parseFieldData,
	deleteCrop,
	updateField,
	createRevExpCategory,
	setManyFieldLedgerEntriesPerHarvestYear,
	deleteManyFieldLedgerEntriesPerHarvestYear,
	setManyCropLedgerEntriesPerHarvestYear,
	deleteManyCropLedgerEntriesPerHarvestYear,
	parseFieldCategoryLedgerData,
	createCrop,
	parseCropData,
	setCropHarvests,
	addPhysicalCropTransaction,
	updatePhysicalCropTransaction,
	getTotalPriceForFlatCropTransaction,
	getTotalPriceForHTACropTransaction,
	getTotalPriceForBasisOnlyCropTransaction,
	getTotalPriceForCropTransaction,
	getTotalPriceForPricedCropTransactions,
	getTotalBushelsForCropTransactions,
	getTotalBushelsForPricedCropTransactions,
	getAvgPriceForFlatCropTransactions,
	getAvgPriceForHTACropTransactions,
	getAvgPriceForBasisOnlyCropTransactions,
	getAvgPriceForPricedCropTransactions,
	getCropMarketPricePerUnit,
	getPricedCropTransactions,
	CropPriceType,
	ContractDisplayType,
	pricingTypeToDisplayString,
	getAllCropTransactionsByType,
	setCropPricesByYear,
	formatCurrency,
	formatAmount,
	getAllCropTransactionsByCrop,
	getHarvestYearFuture,
	getMostCurrentFuture,
	getBrokeragePnL,
	contractsRowsFn,
	getHarvestedAcres,
	calculateCropFieldLedgerTypeForHarvestYear,
	calculateCropLedgerEntriesTotalValue,
	getUniqueProductSlugs,
	getFieldLevelRevenueComponents,
	getFieldLevelExpenseComponents,
	getFieldLevelPnl,
	getCropPrice,
};
