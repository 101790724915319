import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{yield\n\t(hash\n\t\ttrigger=this.trigger root=this.root isInViewport=this.inViewport Content=(component 'lazy-load/content' showContent=this.inViewport)\n\t)\n}}", {"contents":"{{yield\n\t(hash\n\t\ttrigger=this.trigger root=this.root isInViewport=this.inViewport Content=(component 'lazy-load/content' showContent=this.inViewport)\n\t)\n}}","moduleName":"vault-client/components/lazy-load.hbs","parseOptions":{"srcName":"vault-client/components/lazy-load.hbs"}});
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import type { IOptions } from 'intersection-observer-admin';
import type IntersectionObserverAdminService from 'vault-client/services/intersection-observer-admin';

export interface LazyLoadComponentSignature {
	// The arguments accepted by the component
	Args: {
		// If true, the component will stay rendered after the content is loaded
		// Default is true
		stayRendered?: boolean;
		rootMargin?: string;
		threshold?: number | number[];
		root?: HTMLElement;
	};
	// Any blocks yielded by the component
	Blocks: {
		default: [Content: Component, LazyLoadComponent['trigger']];
	};
	// The element to which `...attributes` is applied in the component template
	Element: null;
}

/**
 * A component that renders content when it enters the viewport.
 *
 * Usage:
 * ```hbs
 * <LazyLoad @stayRendered={{false}} @rootMargin="100px" as |lazy|>
 *   <div {{lazy.trigger}}>
 *     When I enter the viewport
 *   </div>
 *   <lazy.Content>
 *     This content will be rendered
 *   </lazy.Content>
 * </LazyLoad>
 * ```
 */
export default class LazyLoadComponent extends Component<LazyLoadComponentSignature> {
	@service declare intersectionObserverAdmin: IntersectionObserverAdminService;

	#root?: HTMLElement;

	@tracked inViewport = false;

	get stayRendered() {
		return this.args.stayRendered ?? true;
	}

	root = modifier((element: HTMLElement) => {
		this.#root = element;
	});

	trigger = modifier((element: HTMLElement) => {
		const options: IOptions = {
			root: this.#root ?? this.args.root,
			rootMargin: this.args.rootMargin,
			threshold: this.args.threshold,
		};

		this.intersectionObserverAdmin.observe(element, options);
		this.setupEnterCallback(element, options);

		if (!this.args.stayRendered) {
			this.setupExitCallback(element);
		}

		// Clean up when the modifier is removed
		return () => this.cleanup(element, options);
	});

	setupEnterCallback(element: HTMLElement, options: IOptions) {
		this.intersectionObserverAdmin.addEnterCallback(element, () => {
			this.inViewport = true;

			if (this.stayRendered) {
				this.cleanup(element, options);
			}
		});
	}

	setupExitCallback(element: HTMLElement) {
		this.intersectionObserverAdmin.addExitCallback(element, () => {
			this.inViewport = false;
		});
	}

	cleanup(element: HTMLElement, options: IOptions) {
		this.intersectionObserverAdmin.unobserve(element, options);
		this.intersectionObserverAdmin.removeElement(element);
	}
}
