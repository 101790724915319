import { gql, useQuery } from 'glimmer-apollo';
import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
	Query,
	CurrentAllocationPositionSortByDTO,
	CurrentAllocationPositionGroupByDTO,
	CropFilterDTO,
	SortByDirection,
	TypeOfPhysicalCropTransactionPricing,
	CropTransactionFilterDTO,
	CurrentAllocationPositionAggregateDTO,
	CropHarvestedAndSoldVolumeFilterDTO,
	CropHarvestYear,
	Crop,
	CropLedgerEntryPerHarvestYearFilterDTO,
	CropHarvestFilterDTO,
	CurrentAllocationPosition,
	TypeOfInstrument,
	Future,
	CropHarvestYearFilterDTO,
	CurrentAllocationPositionFilterDTO,
	type LedgerForecastedEntryAggregateDTO,
	type LedgerForecastedEntryFilterDTO,
	type LedgerForecastedEntryGroupByDTO,
	TypeOfLedgerCategory,
} from 'vault-client/types/graphql-types';
import {
	cornCategorySlugs,
	getHarvestYearFuture,
	getMostCurrentFuture,
	otherCommoditySlugs,
	soyBeanCategorySlugs,
	wheatCategorySlugs,
} from 'vault-client/utils/grain-utils';
import { DateTime } from 'luxon';
import { ModelFrom } from 'vault-client/utils/type-utils';
import MarketDataService from 'vault-client/services/market-data';
import BusinessesBusinessRoute from '../business';
import { uniqueArray } from 'vault-client/utils/general';

const cropSlugsForDashboard = [...cornCategorySlugs, ...soyBeanCategorySlugs, ...wheatCategorySlugs, ...otherCommoditySlugs];

const GET_HARVEST_YEARS = gql`
	query HarvestYears($where: CropHarvestYearFilterDTO!) {
		CropHarvestYears(where: $where) {
			id
			cropId
			effectiveHedgingStartDate
			effectiveHedgingEndDate
			ContractMonthInstrument {
				id
				barchartSymbol
			}
			Crop {
				id
				Category {
					id
					HedgeProduct {
						id
					}
				}
			}
		}
	}
`;

const GET_DASHBOARD_DATA = gql`
	query CropsDashboard(
		$allocationPositionsCalc: CurrentAllocationPositionAggregateDTO!
		$allocationPositionsGroupBy: CurrentAllocationPositionGroupByDTO!
		$allocationPositionsWhere: CurrentAllocationPositionFilterDTO
		$allocationPositionsSortBy: [CurrentAllocationPositionSortByDTO!]
		$htaTransactionsWhere: CropTransactionFilterDTO
		$cropHarvestedAndSoldVolumesWhere: CropHarvestedAndSoldVolumeFilterDTO!
		$harvestYear: Float!
		$cropSlugs: [String!]!
		$allCropsWhere: CropFilterDTO
		$startDate: String!
		$endDate: String!
		$scopeId: String!
		$aggregateRevenueLedgerEntriesWhere: LedgerForecastedEntryFilterDTO!
		$aggregateRevenueLedgerEntriesCalc: LedgerForecastedEntryAggregateDTO!
		$aggregateRevenueLedgerEntriesGroupBy: LedgerForecastedEntryGroupByDTO!
		$aggregateExpenseLedgerEntriesWhere: LedgerForecastedEntryFilterDTO!
		$aggregateExpenseLedgerEntriesCalc: LedgerForecastedEntryAggregateDTO!
		$aggregateExpenseLedgerEntriesGroupBy: LedgerForecastedEntryGroupByDTO!
	) {
		AllCrops: Crops(where: $allCropsWhere) {
			name
			id
			pricingMethodology
			price
			CropHarvests(orderBy: { forecastedHarvestDate: Asc }, where: { forecastedHarvestDate: { gte: $startDate, lte: $endDate } }) {
				id
				forecastedHarvestDate
				acres
				yieldPerAcre
			}
			CropPrices {
				id
				harvestYear
				price
				pricingMethodology
			}
			Category {
				id
				name
				HedgeProduct {
					id
					slug
					MostCurrentFuture {
						id
						displayExpiresAt
						barchartSymbol
						SymbolGroup {
							id
							displayFactor
							fractionDigits
						}
					}
					StandardProductLotSpecification {
						id
						lotSize
						pointValue
					}
				}
			}
			ExpensesForHarvestYear(harvestYear: $harvestYear) {
				harvestYear
				totalUsdFromCropFlatValues
				totalUsdFromCropPerAcreValues
				totalUsdFromFieldFlatValues
				totalUsdFromFieldPerAcreValues
			}
			RevenuesForHarvestYear(harvestYear: $harvestYear) {
				harvestYear
				totalUsdFromCropFlatValues
				totalUsdFromCropPerAcreValues
				totalUsdFromFieldFlatValues
				totalUsdFromFieldPerAcreValues
			}
		}
		MostCurrentFutures: Products(where: { slug: { in: $cropSlugs } }) {
			id
			slug
			name
			MostCurrentFuture {
				id
				displayExpiresAt
				barchartSymbol
				productId
				name
				Product {
					id
					name
				}
				SymbolGroup {
					id
					displayFactor
					fractionDigits
				}
			}
			CurrentFutures {
				id
				displayExpiresAt
				barchartSymbol
				name
				productId
				Product {
					id
					name
				}
				SymbolGroup {
					id
					displayFactor
					fractionDigits
				}
			}
		}
		CropTransactions(scopeId: $scopeId, where: { harvestYear: { equals: $harvestYear } }) {
			id
			bushels
			pricingType
			contractIdentifier
			futuresMonthStartDate
			flatPrice
			deliveryStartDate
			deliveryEndDate
			buyer
			location
			salesDate
			isManuallyAdded
			salesDate
			cropId
			flatPrice
			futuresPrice
			basisPrice
			Crop {
				id
				name
				CropHarvestYears {
					id
					ContractMonthInstrument {
						id
						barchartSymbol
					}
				}
				Category {
					id
					HedgeProduct {
						id
						slug
						barchartDisplayFactor
						barchartSymbolStrikeDisplayFactor
						StandardProductLotSpecification {
							id
							lotSize
							pointValue
						}
						MostCurrentFuture {
							id
							barchartSymbol
						}
					}
				}
			}
		}
		AggregateCurrentAllocationPositions(
			calc: $allocationPositionsCalc
			groupBy: $allocationPositionsGroupBy
			where: $allocationPositionsWhere
			sortBy: $allocationPositionsSortBy
		) {
			sum {
				grossPnl
				unitQuantity
				contractQuantity
			}
			Product {
				slug
			}
		}
		CurrentAllocationPositions(where: $allocationPositionsWhere, orderBy: { effectiveHedgeDate: Asc }) {
			id
			currentWeightedAveragePrice
			openWeightedAveragePrice
			unitQuantity
			contractQuantity
			positionSide
			instrumentType
			optionType
			positionId
			effectiveHedgeDate
			grossPnl
			Account {
				id
				name
			}
			Product {
				id
				name
				slug
				barchartSymbolStrikeDisplayFactor
				barchartDisplayFactor
				StandardProductLotSpecification {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				id
				type
				SymbolGroup {
					id
					fractionDigits
					displayFactor
				}
				... on Option {
					strike
					optionType
					exchangeSymbol
					barchartSymbol
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
				... on Swap {
					expiresAt
					displayExpiresAt
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
				... on Swaption {
					strike
					optionType
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
					SettlementInstrument {
						id
						barchartSymbol
					}
				}
				... on Future {
					barchartSymbol
					exchangeSymbol
				}
			}
		}
		HTATransactions: CropTransactions(where: $htaTransactionsWhere) {
			id
			futuresPrice
			basisPrice
			bushels
			Crop {
				id
				Category {
					id
					HedgeProduct {
						id
						slug
					}
				}
			}
		}
		CropHarvestedAndSoldVolumes(scopeId: $scopeId, where: $cropHarvestedAndSoldVolumesWhere) {
			id
			cropId
			forecastedProductionInBu
			totalSoldRevenueInUsd
			volumeSoldWithNoPriceInBu
			volumeSoldWithFlatPriceInBu
			volumeSoldInBu
			volumeSoldWithBasisPriceOnlyInBu
			volumeSoldWithFuturesPriceOnlyInBu
		}
		AggregateRevenueLedgerEntries: AggregateLedgerForecastedEntries(
			scopeId: $scopeId
			calc: $aggregateRevenueLedgerEntriesCalc
			where: $aggregateRevenueLedgerEntriesWhere
			groupBy: $aggregateRevenueLedgerEntriesGroupBy
		) {
			sum {
				amount
			}
		}
		AggregateExpenseLedgerEntries: AggregateLedgerForecastedEntries(
			scopeId: $scopeId
			calc: $aggregateExpenseLedgerEntriesCalc
			where: $aggregateExpenseLedgerEntriesWhere
			groupBy: $aggregateExpenseLedgerEntriesGroupBy
		) {
			sum {
				amount
			}
		}
	}
`;

const GET_CROP_LEDGER_ENTRIES_PER_HARVEST_YEAR = gql`
	query CropLedgerEntriesPerHarvestYear($scopeId: String!, $where: CropLedgerEntryPerHarvestYearFilterDTO) {
		CropLedgerEntriesPerHarvestYear(scopeId: $scopeId, where: $where) {
			id
			description
			harvestYear
			flatValueInUsd
			perAcreValueInUsd
			cropId
			cropFieldLedgerType
			CropFieldLedgerCategory {
				id
				description
				name
				type
				CropLedgerEntriesPerHarvestYear {
					id
					cropId
					description
					flatValueInUsd
					perAcreValueInUsd
					cropFieldLedgerType
					cropFieldLedgerCategoryId
					CropFieldLedgerCategory {
						name
					}
				}
				FieldLedgerEntriesPerHarvestYear {
					id
					description
					flatValueInUsd
					perAcreValueInUsd
					cropFieldLedgerType
					cropFieldLedgerCategoryId
					CropFieldLedgerCategory {
						name
					}
				}
			}
		}
	}
`;

type DashboardDataQuery = {
	AllCrops: Query['Crops'];
	MostCurrentFutures: Query['Products'];
	CropTransactions: Query['CropTransactions'];
	AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
	CurrentAllocationPositions: Query['CurrentAllocationPositions'];
	HTATransactions: Query['CropTransactions'];
	CropHarvestedAndSoldVolumes: Query['CropHarvestedAndSoldVolumes'];
	AggregateRevenueLedgerEntries: Query['AggregateLedgerForecastedEntries'];
	AggregateExpenseLedgerEntries: Query['AggregateLedgerForecastedEntries'];
};

export type GetDashboardDataQueryArgs = {
	allCropsWhere?: CropFilterDTO;
	allocationPositionsCalc?: CurrentAllocationPositionAggregateDTO;
	allocationPositionsGroupBy?: CurrentAllocationPositionGroupByDTO;
	allocationPositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocationPositionsSortBy?: CurrentAllocationPositionSortByDTO[];
	aggregateRevenueLedgerEntriesWhere?: LedgerForecastedEntryFilterDTO;
	aggregateRevenueLedgerEntriesCalc?: LedgerForecastedEntryAggregateDTO;
	aggregateRevenueLedgerEntriesGroupBy?: LedgerForecastedEntryGroupByDTO;
	aggregateExpenseLedgerEntriesWhere?: LedgerForecastedEntryFilterDTO;
	aggregateExpenseLedgerEntriesCalc?: LedgerForecastedEntryAggregateDTO;
	aggregateExpenseLedgerEntriesGroupBy?: LedgerForecastedEntryGroupByDTO;
	htaTransactionsWhere?: CropTransactionFilterDTO;
	cropHarvestedAndSoldVolumesWhere?: CropHarvestedAndSoldVolumeFilterDTO;
	harvestYear?: number;
	cropSlugs?: string[];
	scopeId?: string;
	startDate?: string;
	endDate?: string;
};

type DashboardRevenuesAndExpensesQuery = {
	CropLedgerEntriesPerHarvestYear: Query['CropLedgerEntriesPerHarvestYear'];
};

export type GetDashboardRevenueAndExpensesQueryArgs = {
	scopeId?: string;
	where?: CropLedgerEntryPerHarvestYearFilterDTO;
	cropHarvestWhere?: CropHarvestFilterDTO;
};
type HarvestYearsQueryArgs = {
	where?: CropHarvestYearFilterDTO;
};

type HarvestYearsQuery = {
	CropHarvestYears: Query['CropHarvestYears'];
};

export default class BusinessesBusinessCropsDashboardRoute extends Route {
	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	@service declare router: RouterService;
	@tracked variables: GetDashboardDataQueryArgs = {};
	@tracked ledgerVariables: GetDashboardRevenueAndExpensesQueryArgs = {};
	@tracked harvestYearsVariables: HarvestYearsQueryArgs = {};

	@service declare marketData: MarketDataService;

	registeredInstruments: string[] = [];

	getHarvestYears = useQuery<HarvestYearsQuery, HarvestYearsQueryArgs>(this, () => [
		GET_HARVEST_YEARS,
		{
			variables: this.harvestYearsVariables,
		},
	]);

	getDashboardData = useQuery<DashboardDataQuery, GetDashboardDataQueryArgs>(this, () => [
		GET_DASHBOARD_DATA,
		{
			variables: this.variables,
		},
	]);

	getCropLedgerEntriesPerHarvestYear = useQuery<DashboardRevenuesAndExpensesQuery, GetDashboardRevenueAndExpensesQueryArgs>(this, () => [
		GET_CROP_LEDGER_ENTRIES_PER_HARVEST_YEAR,
		{
			variables: this.ledgerVariables,
		},
	]);

	async model(params: { startDate: string; endDate: string }) {
		const startDate = params.startDate;
		const endDate = params.endDate;
		const { businessId, getCustomer } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		const harvestYear = DateTime.fromISO(startDate).year;

		this.harvestYearsVariables = {
			where: {
				businessId: {
					equals: businessId,
				},
				harvestYear: {
					equals: harvestYear,
				},
			},
		};

		await this.getHarvestYears.promise;
		const harvestYears = this.getHarvestYears.data?.CropHarvestYears ?? [];
		const positionsFilter = this.getAllocatedPositionsFilterOrNullForHarvestYears(harvestYears, businessId);
		this.variables = {
			scopeId: businessId,
			startDate,
			endDate,
			allCropsWhere: {
				businessId: {
					equals: businessId,
				},
			},
			harvestYear,
			allocationPositionsCalc: {
				sum: {
					grossPnl: true,
					unitQuantity: true,
					contractQuantity: true,
				},
			},
			allocationPositionsGroupBy: {
				Product: {
					slug: true,
				},
			},
			allocationPositionsWhere: positionsFilter,
			allocationPositionsSortBy: [
				{
					Product: {
						slug: SortByDirection.Asc,
					},
				},
			],
			htaTransactionsWhere: {
				businessId: {
					equals: businessId,
				},
				futuresMonthStartDate: {
					gte: startDate,
					lte: endDate,
				},
				pricingType: {
					equals: TypeOfPhysicalCropTransactionPricing.FuturesOnly,
				},
			},
			cropHarvestedAndSoldVolumesWhere: {
				startDate,
				endDate,
			},
			cropSlugs: cropSlugsForDashboard,
			aggregateRevenueLedgerEntriesWhere: {
				LedgerCategory: {
					type: {
						equals: TypeOfLedgerCategory.Revenue,
					},
				},
				date: {
					gte: startDate,
					lte: endDate,
				},
			},
			aggregateRevenueLedgerEntriesCalc: {
				sum: {
					amount: true,
				},
			},
			aggregateRevenueLedgerEntriesGroupBy: {},
			aggregateExpenseLedgerEntriesWhere: {
				LedgerCategory: {
					type: {
						equals: TypeOfLedgerCategory.Expense,
					},
				},
				date: {
					gte: startDate,
					lte: endDate,
				},
			},
			aggregateExpenseLedgerEntriesCalc: {
				sum: {
					amount: true,
				},
			},
			aggregateExpenseLedgerEntriesGroupBy: {},
		};

		await this.getDashboardData.promise;

		const cropIds = this.getDashboardData.data?.AllCrops.map((crop) => crop.id) ?? [];

		this.ledgerVariables = {
			scopeId: businessId,
			where: {
				harvestYear: {
					equals: harvestYear,
				},
				cropId: {
					in: cropIds,
				},
			},
		};

		await Promise.all([this.getCropLedgerEntriesPerHarvestYear.promise]);

		return {
			businessId,
			getDashboardData: this.getDashboardData,
			getHarvestYears: this.getHarvestYears,
			getCustomer,
			harvestYear,
			cropLedgerEntries: this.getCropLedgerEntriesPerHarvestYear,
		};
	}

	afterModel(resolvedModel: ModelFrom<BusinessesBusinessCropsDashboardRoute>): Promise<unknown> | void {
		this.unregisterAllInstruments();

		const cropHarvestYears = resolvedModel.getHarvestYears.data?.CropHarvestYears ?? [];
		const crops = resolvedModel.getDashboardData.data?.AllCrops ?? [];
		const currentAllocationPositions = resolvedModel.getDashboardData.data?.CurrentAllocationPositions ?? [];

		const barChartSymbols = uniqueArray([
			...this.getHarvestYearFutureOrMostCurrentFutureBarchartSymbols(crops, cropHarvestYears),
			...this.getCurrentAllocationPositionsFuturesBarchartSymbols(currentAllocationPositions),
		]);

		this.registerInstrumentsIfSymbolPresent(barChartSymbols);
	}

	deactivate(): void {
		this.unregisterAllInstruments();
	}

	getCurrentAllocationPositionsFuturesBarchartSymbols(positions: CurrentAllocationPosition[]): string[] {
		return positions
			.map((position) => {
				const { instrumentType, Instrument } = position;
				if (instrumentType !== TypeOfInstrument.Future) return null;
				return (Instrument as Future)?.barchartSymbol ?? null;
			})
			.filter((maybeSymbol) => typeof maybeSymbol === 'string');
	}

	getHarvestYearFutureOrMostCurrentFutureBarchartSymbols(crops: Crop[], cropHarvestYears: CropHarvestYear[]): string[] {
		return uniqueArray(
			crops
				.map((crop) => getHarvestYearFuture(crop, cropHarvestYears)?.barchartSymbol ?? getMostCurrentFuture(crop)?.barchartSymbol)
				.filter((maybeSymbol) => typeof maybeSymbol === 'string'),
		);
	}

	registerInstrumentsIfSymbolPresent(barChartSymbols?: string[]) {
		if (!barChartSymbols || barChartSymbols.length === 0) return;

		barChartSymbols.forEach((barchartSymbol) => {
			this.marketData.register(barchartSymbol);
			this.registeredInstruments.push(barchartSymbol);
		});
	}

	unregisterAllInstruments() {
		this.registeredInstruments.forEach((barchartSymbol) => {
			this.marketData.unregister(barchartSymbol);
		});
		this.registeredInstruments = [];
	}

	// Helper function to generate positions filter
	getAllocatedPositionsFilterOrNullForHarvestYears(
		harvestYears: CropHarvestYear[],
		businessId: string,
	): CurrentAllocationPositionFilterDTO | undefined {
		const hedgedHarvestYears = harvestYears.filter(
			(harvestYear) => harvestYear.effectiveHedgingStartDate && harvestYear.Crop?.Category?.HedgeProduct?.id,
		);

		return {
			OR: [
				...hedgedHarvestYears.map((hedgedHarvestYear) => ({
					effectiveHedgeDate: {
						gte: hedgedHarvestYear.effectiveHedgingStartDate!,
						lte: hedgedHarvestYear.effectiveHedgingEndDate ?? undefined,
					},
					productId: {
						equals: hedgedHarvestYear.Crop!.Category!.HedgeProduct!.id,
					},
				})),
				// Kludge used to ensure no positions are returned if there are no hedged harvest years
				// TODO: Refactor and remove this once we update glimmer-apollo past v0.6.6 (skip option on useQuery becomes available)
				{ id: { in: [] } },
			],
			businessId: {
				equals: businessId,
			},
		};
	}

	generateCurrentAllocationPositionsWhere(currentAllocationPositionIds: string[]): CurrentAllocationPositionFilterDTO {
		return {
			id: {
				in: currentAllocationPositionIds,
			},
		};
	}
}
