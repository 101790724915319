import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import Future from './future';
import Option from './option';
import FutureTransaction from './future-transaction';
import OptionTransaction from './option-transaction';
import Swap from './swap';
import SwapTransaction from './swap-transaction';
import SwaptionTransaction from './swaption-transaction';
import Swaption from './swaption';
import {
	CurrentPosition,
	CurrentPositionComponent,
	Transaction,
	Swaption as SwaptionType,
	Option as OptionType,
	TypeOfInstrument,
} from 'vault-client/types/graphql-types';

export default class Position {
	@service marketData: any;

	@tracked Instrument;
	@tracked Account;
	@tracked trades;

	@tracked displayExpiresAt;
	@tracked quantity;
	@tracked _unrealizedPl: number | null | undefined;
	@tracked _realizedPl: number | null | undefined;

	@tracked instrumentType;
	children;
	isCollapsed;
	@tracked id: string;

	@tracked _commisionTotal;
	@tracked _feeTotal;
	@tracked currentWeightedAveragePrice;

	constructor(owner: any, position: CurrentPosition) {
		setOwner(this, owner);
		if (position.Instrument.type === TypeOfInstrument.Future) {
			this.instrumentType = 'Future';
			this.Instrument = new Future(position.Instrument as any);
			this.trades = position.Transactions?.map((trade: Transaction) => {
				return new FutureTransaction(owner, trade, position.Instrument, position.Account);
			});
			this.realizedPl = position.realizedPnl;
			this.unrealizedPl = position.unrealizedPnl;
		}

		if (position.Instrument.type === TypeOfInstrument.Option) {
			this.instrumentType = `${(position.Instrument as unknown as OptionType).optionType}`;
			this.Instrument = new Option(position.Instrument as any);
			this.trades = position.Transactions?.map((trade: Transaction) => {
				return new OptionTransaction(owner, trade, position.Instrument, position.Account);
			});
			this.realizedPl = position.realizedPnl;
			this.unrealizedPl = position.unrealizedPnl;
		}

		if (position.Instrument.type === TypeOfInstrument.Swap) {
			this.instrumentType = 'Swap';
			this.Instrument = new Swap(position.Instrument as any);
			this.trades = position.Transactions?.map((trade: Transaction) => {
				return new SwapTransaction(owner, trade, position.Instrument, position.Account);
			});
			this.realizedPl = position.realizedPnl;
			this.unrealizedPl = position.unrealizedPnl;
		}

		if (position.Instrument.type === TypeOfInstrument.Swaption) {
			this.instrumentType = `${(position.Instrument as unknown as SwaptionType).optionType} Swaption`;
			this.Instrument = new Swaption(position.Instrument as any);
			this.trades = position.Transactions?.map((trade: Transaction) => {
				return new SwaptionTransaction(owner, trade, position.Instrument, position.Account, true);
			});
			this.realizedPl = position.realizedPnl;
			this.unrealizedPl = position.unrealizedPnl;
		}

		this.Account = position.Account;
		if (position.CurrentPositionComponents) {
			this.children = position.CurrentPositionComponents.map((component: CurrentPositionComponent) => {
				return {
					...component,
					quantityInContracts: component.quantity,
					unrealizedPl: component.unrealizedPnl,
					currentWeightedAveragePrice: component.price,
					instrumentType: this.Instrument?.instrumentType,
					Instrument: this.Instrument,
					marketDataInstrument: this.marketDataInstrument,
					positionId: position.id,
				};
			});
		}
		this.isCollapsed = true;
		this.id = position.id;
		this.displayExpiresAt = position.displayExpiresAt;
		this.quantity = position.quantity;

		this._commisionTotal = position.commissionTotal;
		this._feeTotal = position.feeTotal;
		this.currentWeightedAveragePrice = position.currentWeightedAveragePrice;

		// DGC: For some reason, I had to push this to the next runloop to prevent
		// a Glimmer error.
		const obj = { Instrument: this.Instrument, marketData: this.marketData };
		next(obj, function () {
			obj.marketData.register(obj.Instrument?.barchartSymbol);
		});
	}

	get unrealizedPl() {
		return this._unrealizedPl;
	}
	set unrealizedPl(value: number | null | undefined) {
		this._unrealizedPl = value;
	}

	get realizedPl(): number | null | undefined {
		return this._realizedPl;
	}
	set realizedPl(value: number | null | undefined) {
		this._realizedPl = value;
	}

	get accountId() {
		return this.Account.id;
	}

	get instrumentId() {
		return this.Instrument?.id;
	}

	get positionId() {
		return this.id;
	}

	// Assume that quantity is contracts for most positions.
	get quantityInContracts() {
		return this.quantity;
	}

	get absQuantityInContracts() {
		return Math.abs(this.quantityInContracts);
	}

	get side() {
		if (this.quantity > 0) {
			return 'Long';
		} else if (this.quantity < 0) {
			return 'Short';
		} else {
			return 'Flat';
		}
	}

	// Assume that the instrument is the market data instrument for most positions.
	get marketDataInstrument() {
		return this.Instrument;
	}

	get commission() {
		if (this._commisionTotal != null) return this._commisionTotal;

		return (
			this.trades?.reduce((acc, trade) => {
				return acc + trade.commission;
			}, 0) ?? 0
		);
	}

	get nonCommissionFees() {
		if (this._feeTotal != null) return this._feeTotal;

		return (
			this.trades?.reduce((acc, trade) => {
				return acc + trade.nonCommissionFees;
			}, 0) ?? 0
		);
	}

	get grossPl() {
		return (this.unrealizedPl ?? 0) + (this.realizedPl ?? 0);
	}

	get netPl() {
		return this.grossPl + this.nonCommissionFees + this.commission;
	}
}
