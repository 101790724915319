import { registerDestructor } from '@ember/destroyable';
import Service from '@ember/service';
import IntersectionObserverAdmin, { type IOptions } from 'intersection-observer-admin';

export default class IntersectionObserverAdminService extends Service {
	intersectionObserverAdmin = new IntersectionObserverAdmin();

	constructor(args: Object) {
		super(args);
		registerDestructor(this, () => this.cleanup(this));
	}

	/**
	 * Unobserve target element and remove element from static admin
	 *
	 * @method unobserve
	 * @param {HTMLElement|Window} target
	 * @param {Object} options
	 * @public
	 */
	observe(element: HTMLElement, options: IOptions) {
		this.intersectionObserverAdmin.observe(element, options);
	}

	/**
	 * Unobserve target element and remove element from static admin
	 *
	 * @method unobserve
	 * @param {HTMLElement|Window} target
	 * @param {Object} options
	 * @public
	 */
	unobserve(element: HTMLElement, options: IOptions) {
		this.intersectionObserverAdmin.unobserve(element, options);
	}

	/**
	 * register event to handle when intersection observer detects enter
	 *
	 * @method addEnterCallback
	 * @public
	 */
	addEnterCallback(element: HTMLElement, callback: (entry: IntersectionObserverEntry) => void) {
		this.intersectionObserverAdmin.addEnterCallback(element, callback);
	}

	/**
	 * register event to handle when intersection observer detects exit
	 *
	 * @method addExitCallback
	 * @public
	 */
	addExitCallback(element: HTMLElement, callback: (entry: IntersectionObserverEntry) => void) {
		this.intersectionObserverAdmin.addExitCallback(element, callback);
	}

	/**
	 * retrieve registered callback and call with data
	 *
	 * @method dispatchEnterCallback
	 * @public
	 */
	dispatchEnterCallback(element: HTMLElement | Window, entry: any): void {
		this.intersectionObserverAdmin.dispatchEnterCallback(element, entry);
	}

	/**
	 * retrieve registered callback and call with data on exit
	 *
	 * @method dispatchExitCallback
	 * @public
	 */
	dispatchExitCallback(element: HTMLElement | Window, entry: any): void {
		this.intersectionObserverAdmin.dispatchExitCallback(element, entry);
	}

	/**
	 * cleanup removes provided elements from both registries
	 *
	 * @method removeElement
	 * @public
	 *
	 */
	removeElement(element: HTMLElement | Window): void {
		this.intersectionObserverAdmin.removeElement(element);
	}

	/**
	 * checks whether element exists in either registry
	 *
	 * @method elementExists
	 * @public
	 *
	 */
	elementExists(element: HTMLElement | Window): boolean {
		return this.intersectionObserverAdmin.elementExists(element);
	}

	cleanup(instance: IntersectionObserverAdminService) {
		instance.intersectionObserverAdmin.destroy();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	interface Registry {
		intersectionObserverAdmin: IntersectionObserverAdminService;
	}
}
