import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import Future from './future';
import Option from './option';
import Swap from './swap';
import Swaption from './swaption';
import MarketDataService from 'vault-client/services/market-data';
import {
	CurrentAllocationPosition,
	PositionComponentAllocation,
	Swaption as SwaptionType,
	Option as OptionType,
	TypeOfInstrument,
	Account,
	Product,
} from 'vault-client/types/graphql-types';
import type Owner from '@ember/owner';

export default class AllocationPosition {
	@service declare marketData: MarketDataService;

	@tracked Instrument: Future | Option | Swap | Swaption | undefined;
	@tracked Account: Account | undefined;
	@tracked Product: Product | undefined;
	@tracked contractQuantity: number = 0;
	@tracked grossPnl: number = 0;

	@tracked instrumentType: string = '';
	children: Array<PositionComponentAllocation> = [];
	isCollapsed: boolean = true;
	@tracked id: string = '';
	@tracked positionId: string = '';
	@tracked effectiveHedgeDate: string = '';
	@tracked openWeightedAveragePrice: number = 0;
	@tracked positionComponentAllocations: PositionComponentAllocation[] = [];

	constructor(owner: Owner, position: CurrentAllocationPosition) {
		setOwner(this, owner);
		this.Product = position.Product;
		if (position.Instrument.type === TypeOfInstrument.Future) {
			this.instrumentType = 'Future';
			this.Instrument = new Future(position.Instrument as any);
		}

		if (position.Instrument.type === TypeOfInstrument.Option) {
			this.instrumentType = `${(position.Instrument as OptionType).optionType}`;
			this.Instrument = new Option(position.Instrument as any);
		}

		if (position.Instrument.type === TypeOfInstrument.Swap) {
			this.instrumentType = 'Swap';
			this.Instrument = new Swap(position.Instrument as any);
		}

		if (position.Instrument.type === TypeOfInstrument.Swaption) {
			this.instrumentType = `${(position.Instrument as SwaptionType).optionType} Swaption`;
			this.Instrument = new Swaption(position.Instrument as any);
		}

		this.Account = position.Account;
		this.positionComponentAllocations = position.PositionComponentAllocations;

		this.children = [];
		this.isCollapsed = true;
		this.id = position.id;
		this.positionId = position.positionId;
		this.effectiveHedgeDate = position.effectiveHedgeDate;

		// Use contractQuantity for quantity
		this.contractQuantity = position.contractQuantity ?? 0;
		this.openWeightedAveragePrice = position.openWeightedAveragePrice ?? 0;
		this.grossPnl = position.grossPnl ?? 0;

		// DGC: For some reason, I had to push this to the next runloop to prevent
		// a Glimmer error.
		const obj = { Instrument: this.Instrument, marketData: this.marketData };
		next(obj, function () {
			const barchartSymbol = obj.Instrument?.barchartSymbol;
			if (barchartSymbol) {
				obj.marketData.register(barchartSymbol);
			}
		});
	}

	// Assume that quantity is contracts for most positions.
	get quantityInContracts() {
		return this.contractQuantity;
	}

	get side() {
		if (this.contractQuantity > 0) {
			return 'Long';
		} else if (this.contractQuantity < 0) {
			return 'Short';
		} else {
			return 'Flat';
		}
	}

	// Assume that the instrument is the market data instrument for most positions.
	get marketDataInstrument() {
		return this.Instrument;
	}
}
