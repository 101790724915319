import type Owner from '@ember/owner';
import AllocationPosition from 'vault-client/models/allocation-position';
import {
	CurrentAllocationPosition,
	Future,
	Option,
	Swap,
	Swaption,
	type AggregateCurrentAllocationPositionDTO,
	type Maybe,
} from 'vault-client/types/graphql-types';
import { safeSum } from '../precision-math';

type InstrumentWithTypename = (Future | Option | Swap | Swaption) & {
	__typename: 'Future' | 'Option' | 'Swap' | 'Swaption';
};

const positionTotalKeys = ['contractQuantity', 'grossPnl'] as const satisfies (keyof AllocationPosition)[];
type PositionsTotal = {
	[key in (typeof positionTotalKeys)[number]]: Maybe<number>;
};

function itemsFn(rows: CurrentAllocationPosition[], owner: Owner): AllocationPosition[] {
	return rows
		.filter(
			(row): row is CurrentAllocationPosition & { Instrument: InstrumentWithTypename } =>
				row.Instrument !== null && row.Instrument !== undefined && '__typename' in row.Instrument,
		)
		.map((row) => new AllocationPosition(owner, row));
}

function getPositionsTotal(aggregateCurrentAllocationPositions: AggregateCurrentAllocationPositionDTO[]): PositionsTotal {
	return aggregateCurrentAllocationPositions.reduce<PositionsTotal>(
		(total, { sum: { contractQuantity, grossPnl } }) => {
			total.contractQuantity = safeSum(total.contractQuantity, contractQuantity);
			total.grossPnl = safeSum(total.grossPnl, grossPnl);
			return total;
		},
		positionTotalKeys.reduce(
			(acc, key) => {
				acc[key] = null;
				return acc;
			},
			{} as Record<keyof PositionsTotal, Maybe<number>>,
		),
	);
}

export { itemsFn, getPositionsTotal };